import React, { FC, memo, ReactEventHandler, useState } from "react";
import Webcam from "react-webcam";

interface ICameraConfig {
  onStopLoading: () => void;
  videoRef: React.LegacyRef<Webcam> | undefined;
}

const CameraConfig: FC<ICameraConfig> = ({ onStopLoading, videoRef }) => {
  const [loading, setLoading] = useState<boolean>(true);

  return (
    <>
      {loading && (
        <div className="spinner-container">
          <div className="loading-spinner"></div>
        </div>
      )}
      <Webcam
        style={{
          position: "absolute",
          height: "100vh",
          width: "100vw",
          objectFit: "cover",
          objectPosition: "center",
        }}
        id="video"
        onLoadedData={() => {
          setLoading(false);
          onStopLoading();
        }}
        autoPlay={true}
        controls={false}
        mirrored={true}
        screenshotFormat={"image/jpeg"}
        ref={videoRef}
        screenshotQuality={0.5}
        preload={"none"}
        playsInline
        muted
      />
    </>
  );
};

export default memo(CameraConfig);
