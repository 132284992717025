import React, { FC, memo, useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Ponto from "./ponto";
import PontoError from "./ponto/error";
import PontoSuccess from "./ponto/success";
import * as faceapi from "face-api.js";
import PontoCamera from "./ponto/camera";
import PontoGeolocation from "./ponto/geolocation";
import PontoEnvio from "./ponto/Envio";
import { CacheBuster } from "react-cache-buster/dist/CacheBuster";
import packageJson from "../../package.json";

const Pages: FC = () => {
  const [loading, setLoading] = useState(true);
  const navigation = useNavigate();
  const location = useLocation();

  useEffect(() => {
    (async () => {
      try {
        await faceapi.loadFaceExpressionModel("/models");
        await faceapi.loadTinyFaceDetectorModel("/models");
        setLoading(false);
      } catch (err) {
        console.log(err);
        navigation(`/ponto/error?error=${JSON.stringify(err)}`, {
          state: location.state,
        });
        setLoading(false);
      }
    })();
  }, []);

  const isProduction = process.env.NODE_ENV === "production";

  return (
    <CacheBuster
      currentVersion={packageJson.version}
      isEnabled={isProduction} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      loadingComponent={
        <div className="spinner-container">
          <div className="loading-spinner"></div>
        </div>
      } //If not pass, nothing appears at the time of new version check.
      metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
    >
      {loading && (
        <div className="spinner-container">
          <div className="loading-spinner"></div>
        </div>
      )}
      {!loading && (
        <Routes>
          <Route
            caseSensitive
            path="ponto/:listId/:persistedFaceId/:pontoId"
            element={<Ponto />}
          />
          <Route
            caseSensitive
            path="ponto/:listId/:persistedFaceId/:pontoId/camera"
            element={<PontoCamera />}
          />
          <Route
            caseSensitive
            path="ponto/:listId/:persistedFaceId/:pontoId/camera/geolocation"
            element={<PontoGeolocation />}
          />
          <Route
            caseSensitive
            path="ponto/:listId/:persistedFaceId/:pontoId/camera/geolocation/envio"
            element={<PontoEnvio />}
          />

          <Route
            caseSensitive
            path="ponto/:listId/:persistedFaceId/:pontoId/camera/geolocation/envio/success"
            element={<PontoSuccess />}
          />

          <Route
            caseSensitive
            path="ponto/client/:clientId"
            element={<Ponto />}
          />
          <Route
            caseSensitive
            path="ponto/client/:clientId/camera"
            element={<PontoCamera />}
          />
          <Route
            caseSensitive
            path="ponto/client/:clientId/camera/geolocation"
            element={<PontoGeolocation />}
          />
          <Route
            caseSensitive
            path="ponto/client/:clientId/camera/geolocation/envio"
            element={<PontoEnvio />}
          />

          <Route
            caseSensitive
            path="ponto/client/:clientId/camera/geolocation/envio/success"
            element={<PontoSuccess />}
          />

          <Route caseSensitive path="ponto/error" element={<PontoError />} />
        </Routes>
      )}
    </CacheBuster>
  );
};

export default memo(Pages);
