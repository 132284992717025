import React, { FC, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Instructions, InstructionsTitle, Logo, PontoPage } from "./../styles";
import logo from "./../../../images/ponto_zap.png";

const PontoGeolocation: FC = () => {
  const navigation = useNavigate();
  const location = useLocation();
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      function (position) {
        sessionStorage.setItem(
          "@latLng",
          `${position.coords.latitude},${position.coords.longitude}|${
            position.coords.accuracy || 0
          }|${position.coords.altitude || 0}`
        );
        navigation("envio", {
          state: location.state,
        });
      },
      (e) => {
        navigation(`/ponto/error`, {
          state: {
            ...(location.state as any),
            err: e.message,
          },
        });
      },
      {
        enableHighAccuracy: true,
        maximumAge: 120000,
        timeout: 25000,
      }
    );
  }, []);

  return (
    <PontoPage>
      <Instructions>
        <Logo src={logo} />
        <InstructionsTitle>
          Estamos te localizando, aguarde...
        </InstructionsTitle>
      </Instructions>
    </PontoPage>
  );
};

export default PontoGeolocation;
