import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as faceapi from "face-api.js";
import Webcam from "react-webcam";

import {
  CameraInstructions,
  CameraInstructionsTitle,
  PhotoContent,
  PontoPage,
} from "./../styles";
import CameraConfig from "./camera";

const INITIAL_MSG_CAMERA = "Posicione seu rosto no centro";
const SMILE_MSG_CAMERA = "Fique parado e SORRIA";
const MOVIMENT_FACE_CAMERA = "Movimente seu rosto, e sorria novamente";

const PontoCamera: FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [mensagem, setMensagem] = useState<string>(INITIAL_MSG_CAMERA);
  const navigation = useNavigate();
  const location = useLocation();
  const [photo, setPhoto] = useState<string | undefined | null>(undefined);
  const videoRef = useRef<Webcam>(null);

  useEffect(() => {
    const loop = () => {
      const video = document.getElementById("video") as HTMLVideoElement;
      if (!photo && video && !loading) {
        faceapi
          .detectSingleFace(video, new faceapi.TinyFaceDetectorOptions())
          .withFaceExpressions()
          .run()
          .then((result) => {
            if (result && result.expressions) {
              if (mensagem !== SMILE_MSG_CAMERA) {
                setMensagem(SMILE_MSG_CAMERA);
              }
              let happiness = 0;
              let neutral = 0;
              let sad = 0;
              if (result.expressions.hasOwnProperty("happy")) {
                happiness = result.expressions.happy;
              }
              if (result.expressions.hasOwnProperty("neutral")) {
                neutral = result.expressions.neutral;
              }
              if (result.expressions.hasOwnProperty("sad")) {
                sad = result.expressions.sad;
              }
              console.log(happiness);
              if (happiness >= 0.7 && !photo) {
                const photo = videoRef.current?.getScreenshot();
                if (photo) {
                  sessionStorage.setItem("@photo", photo);
                }
                setPhoto(photo);
                navigation("geolocation", {
                  state: location.state,
                });
              } else {
                setTimeout(loop, 10);
              }
            } else {
              if (mensagem !== INITIAL_MSG_CAMERA) {
                setMensagem(INITIAL_MSG_CAMERA);
              }
              setTimeout(loop, 10);
            }
          })
          .catch((err) => {
            navigation(`/ponto/error`, {
              state: {
                ...(location.state as any),
                err,
              },
            });
          });
      } else {
        setTimeout(loop, 50);
      }
    };

    loop();
  }, [loading, photo]);

  return (
    <PontoPage>
      <PhotoContent>
        {!loading && (
          <CameraInstructions>
            <CameraInstructionsTitle>
              {mensagem || INITIAL_MSG_CAMERA}
            </CameraInstructionsTitle>
          </CameraInstructions>
        )}
        <CameraConfig
          onStopLoading={() => setLoading(false)}
          videoRef={videoRef}
        />
      </PhotoContent>
    </PontoPage>
  );
};

export default PontoCamera;
