import React, { FC } from "react";
import { useNavigate } from "react-router-dom";

import {
  Instructions,
  InstructionsTitle,
  Logo,
  PontoPage,
  StartPhotoBtn,
} from "./styles";
import logo from "./../../images/ponto_zap.png";

const Ponto: FC = () => {
  const navigation = useNavigate();

  return (
    <PontoPage>
      <Instructions>
        <Logo src={logo} />
        <InstructionsTitle>
          Para realizar seu ponto, faça uma foto sorrindo seguindo as
          orientações:
        </InstructionsTitle>
        <ol>
          <li>Local bem iluminado</li>
          <li>Sem chapéu ou boné</li>
          <li>Sem óculos escuros</li>
        </ol>
        <StartPhotoBtn
          onClick={() => {
            navigation(`camera`, {
              state: {
                initialUri: window.location.pathname,
              },
            });
          }}
        >
          Iniciar
        </StartPhotoBtn>
      </Instructions>
    </PontoPage>
  );
};

export default Ponto;
