import React, { FC, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { Instructions, InstructionsTitle, Logo, PontoPage } from "./../styles";
import axios from "axios";
import logo from "./../../../images/ponto_zap.png";

const PontoEnvio: FC = () => {
  const navigation = useNavigate();
  let { listId, clientId, persistedFaceId, pontoId } = useParams<{
    listId?: string;
    clientId?: string;
    persistedFaceId: string;
    pontoId: string;
  }>();
  const location = useLocation();

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const processData = async () => {
      const photo = sessionStorage.getItem("@photo");
      const latLng = sessionStorage.getItem("@latLng");
      if (photo && latLng) {
        setLoading(true);

        try {
          const client = axios.create();
          const {
            data: {
              success,
              data: { pontoRegistrado, redirectUri, mensagem, ponto },
            },
          } = await client.post<{
            success: true;
            data: {
              confidence: number;
              mensagem: string;
              pontoRegistrado: boolean;
              redirectUri: string;
              ponto?: {
                unidade: string;
                nomeColaborador: string;
                cpfColaborador: string;
                dataHoraPonto: string;
                dataHoraPontoString?: string;
              };
            };
          }>(`${process.env.REACT_APP_FACE_API}/api/v1/compara-face`, {
            ListId: listId,
            ClientId: clientId,
            PersistedFaceId: persistedFaceId,
            LatLng: latLng,
            PontoId: pontoId,
            Foto: photo.replace(/data:image\/[^0-9,.]+;base64,/g, ""),
          });

          if (success && pontoRegistrado) {
            navigation(
              `success?message=${mensagem}&redirectUri=${redirectUri}`,
              {
                state: {
                  ...(location.state as any),
                  ponto,
                },
              }
            );
            sessionStorage.clear();
          } else {
            navigation(`/ponto/error`, {
              state: {
                ...(location.state as any),
                err: mensagem,
              },
            });
          }
        } catch (e: any) {
          const err = e?.response?.data?.data || [];
          setLoading(false);
          navigation(`/ponto/error`, {
            state: {
              ...(location.state as any),
              err: err[0]?.message || err[0]?.messagem || err?.mensagem || "",
            },
          });
        }
      }
    };

    processData();
  }, []);

  return (
    <PontoPage>
      {loading && (
        <div className="spinner-container">
          <div className="loading-spinner"></div>
        </div>
      )}
      <Instructions>
        <Logo src={logo} />
        <InstructionsTitle>
          Estamos registrando seu ponto, aguarde...
        </InstructionsTitle>
      </Instructions>
    </PontoPage>
  );
};

export default PontoEnvio;
