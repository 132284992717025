import { FC, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "./../../../images/ponto_zap.png";

import {
  ErrSpan,
  Instructions,
  InstructionsTitle,
  Logo,
  PontoPage,
  StartPhotoBtn,
} from "./../styles";

const PontoError: FC = () => {
  const navigation = useNavigate();
  const location = useLocation();
  const state = location.state as {
    initialUri: string;
    err?: string;
  };

  const getErrMsg = (err: string): string => {
    try {
      const parseJson = JSON.parse(err);

      return parseJson?.mensagem || "Erro não identificado";
    } catch {
      return err;
    }
  };

  const handleMsgErro = useMemo(() => {
    if (state.err && getErrMsg(state.err)) {
      return getErrMsg(state.err);
    } else if (state.err) {
      return state.err;
    } else {
      return "Houve uma falha para registrar seu ponto, verifique com a equipe técnica !";
    }
  }, [getErrMsg, state]);

  return (
    <PontoPage>
      <Instructions>
        <Logo src={logo} />
        <InstructionsTitle>{handleMsgErro}</InstructionsTitle>
        <StartPhotoBtn
          onClick={() => {
            if (state.initialUri) {
              navigation(state.initialUri, {
                replace: true,
              });
            } else {
              window.history.back();
            }
          }}
        >
          Voltar ao Ínicio
        </StartPhotoBtn>
      </Instructions>
    </PontoPage>
  );
};

export default PontoError;
