import React, { FC } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import logo from "./../../../images/ponto_zap.png";

import {
  Instructions,
  InstructionsTitle,
  Logo,
  PontoPage,
  StartPhotoBtn,
} from "./../styles";

const PontoSuccess: FC = () => {
  let [searchParams] = useSearchParams();
  const location = useLocation();
  const { ponto } = location.state as {
    ponto?: {
      unidade: string;
      nomeColaborador: string;
      cpfColaborador: string;
      dataHoraPonto: string;
      dataHoraPontoString?: string;
    };
  };

  const redirectUri = searchParams.get("redirectUri");

  const isClient = location.pathname.includes("client");

  return (
    <PontoPage>
      <Instructions>
        <Logo src={logo} />
        <InstructionsTitle>
          Olá, {ponto?.nomeColaborador} - {ponto?.cpfColaborador}. Seu ponto foi
          registrado com sucesso!{" "}Às{" "}
          {Boolean(ponto?.dataHoraPontoString) ? ponto?.dataHoraPontoString : ponto?.dataHoraPonto
            ? new Date(ponto?.dataHoraPonto).toLocaleDateString()
            : ""}{" "}{ponto?.dataHoraPonto
              ? new Date(ponto?.dataHoraPonto).toLocaleTimeString()
              : ""}{" "}
          na unidade {ponto?.unidade}
        </InstructionsTitle>
        <span>{searchParams.get("message")}</span>
        {ponto && <span>{}</span>}
        {redirectUri && !isClient && (
          <StartPhotoBtn
            onClick={() => {
              if (redirectUri) {
                window.open(redirectUri, "_self");
                setTimeout(() => {
                  window.close();
                }, 1000);
              }
            }}
          >
            Voltar ao whatsapp
          </StartPhotoBtn>
        )}
        {isClient && (
          <StartPhotoBtn
            onClick={() => {
              window.location.replace(
                `${location.pathname.replace(
                  "/camera/geolocation/envio/success",
                  ""
                )}`
              );
            }}
          >
            Voltar ao Ínicio
          </StartPhotoBtn>
        )}
      </Instructions>
    </PontoPage>
  );
};

export default PontoSuccess;
