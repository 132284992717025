import styled from "styled-components";

export const PontoPage = styled.div`
  height: 100vh;
  width: 100vw;
`;

export const Logo = styled.img`
  max-width: 300px;
`;

export const PhotoContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
`;

export const Instructions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 100vh;
`;

export const CameraInstructions = styled.div`
  position: absolute;
  z-index: 99999;
  padding: 5px;
  top: auto;
  bottom: 0px;
  background-color: rgba(20, 20, 20, 0.5);
  width: 100vw;
`;

export const CameraInstructionsTitle = styled.h3`
  font-family: "FordAntennaBold";
  text-align: center;
  color: #fff;
`;

export const ErrSpan = styled.span`
  font-family: "FordAntennaLight";
  text-align: center;
  color: #000;
  padding: 15px;
`;

export const InstructionsTitle = styled.h3`
  text-align: center;
  font-family: "FordAntennaBold";
`;

export const StartPhotoBtn = styled.button`
  background-color: #06b45d;
  font-size: 1em;
  margin: 1em;
  padding: 0.3em 1.5em;
  border: 0px solid palevioletred;
  color: #fff;
  border-radius: 3px;
  cursor: pointer;
`;
